import React, { useEffect } from 'react';
import { ZonedDateTime } from '@js-joda/core';
import { captureException } from '@sentry/react';
import { useRouteError } from 'react-router';
import styled from 'styled-components';
import { Header } from '../components/reusable/Header';
import Text, { TextKind } from '../components/reusable/Text';

const StyledErrorComponent = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;

const VITE_BUNDLE_ERROR_AUTO_RELOAD = 'viteBundleError-autoReload';

export const ErrorBoundary = () => {
  const routeError = useRouteError();
  const now = ZonedDateTime.now();
  const lastAutoReload = localStorage.getItem(VITE_BUNDLE_ERROR_AUTO_RELOAD);
  const zdtLastReload = lastAutoReload
    ? ZonedDateTime.parse(lastAutoReload)
    : null;
  const recentlyAutoRefreshed = zdtLastReload?.isAfter(now.minusSeconds(15));

  const errorMessage = (routeError as Error)?.message;
  const isBundleError = errorMessage?.includes(
    'Failed to fetch dynamically imported module',
  );
  const failedToAutoRecover = isBundleError && recentlyAutoRefreshed;

  useEffect(() => {
    /**
     * check if we've recently attempted to auto-recover from a bundle error
     * and failed to do so, alert to sentry if so
     */
    if (failedToAutoRecover) {
      captureException(
        new Error('Could not automatically recover from bundle error'),
        {
          extra: {
            routeError,
          },
        },
      );
    }
  }, [failedToAutoRecover, routeError]);

  useEffect(() => {
    /**
     * if we're in a bundle error state and we haven't recently auto-reloaded,
     * automatically refresh the page after a short delay
     */
    if (
      isBundleError &&
      (!lastAutoReload || (lastAutoReload && !recentlyAutoRefreshed))
    ) {
      setTimeout(() => {
        localStorage.setItem(
          VITE_BUNDLE_ERROR_AUTO_RELOAD,
          ZonedDateTime.now().toString(),
        );
        window.location.reload();
      }, 1000);
    }
  }, [isBundleError, lastAutoReload, recentlyAutoRefreshed]);

  return (
    <>
      <Header logoutOnly />
      <StyledErrorComponent data-testid="app-error-boundary">
        {isBundleError && !failedToAutoRecover ? (
          <Text kind={TextKind.BodyL}>
            New version of CoachHub is available, updating...
          </Text>
        ) : (
          <>
            <Text kind={TextKind.BodyL}>
              An unknown error occurred, please try again in a few minutes.
            </Text>
            <Text kind={TextKind.BodyL}>
              If the problem persists, contact support.
            </Text>
          </>
        )}
      </StyledErrorComponent>
    </>
  );
};
