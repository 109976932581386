import React, { createElement, useEffect } from 'react';

import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import '@js-joda/locale_en-us';
import '@js-joda/timezone';
import * as Sentry from '@sentry/react';
import { configure } from '@teamexos/fit-shared';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';
import Providers from '../Providers';
import config from '../aws-exports';
import './app.css';
import { BrowserRouter } from './routes';

if (process.env.NODE_ENV !== 'production') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const ignoredSamplingUrls = ['localhost', 'mixpanel'];

if (!Sentry.isInitialized()) {
  Sentry.init({
    release: process.env.SENTRY_RELEASE,
    dsn: process.env.GATSBY_SENTRY_DSN,
    environment: process.env.GATSBY_ENV_LEVEL || 'dev',
    tracesSampler: (samplingContext) => {
      const traceLocation = samplingContext.location?.toString();
      if (
        ignoredSamplingUrls.some((ignoredSamplingUrl) =>
          traceLocation?.includes(ignoredSamplingUrl),
        )
      ) {
        return false;
      }
      return samplingContext.attributes?.['sentry.sample_rate'] ?? true;
    },
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.GATSBY_ENV_LEVEL === 'prod' ? 0.1 : 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

configure(config[process.env.GATSBY_ENV_LEVEL ?? 'dev']);

const RenderIt = () => (
  <Providers>
    <BrowserRouter />
  </Providers>
);

let container: HTMLElement | null;

document.addEventListener('DOMContentLoaded', () => {
  if (!container) {
    container = document.getElementById('root');
    if (container) {
      const root = createRoot(container, {
        onUncaughtError: (err) => {
          Sentry.addBreadcrumb({
            message: 'Root React Error Handler: onUncaughtError',
          });
          Sentry.captureException(err);
        },
        onCaughtError: (err) => {
          Sentry.addBreadcrumb({
            message: 'Root React Error Handler: onCaughtError',
          });
          Sentry.captureException(err);
        },
        onRecoverableError: (err) => {
          Sentry.addBreadcrumb({
            message: 'Root React Error Handler: onRecoverableError',
          });
          Sentry.captureException(err);
        },
      });
      root.render(createElement(RenderIt));
    }
  }
});
