import React from 'react';

import styled from 'styled-components';
import { BORDER_GRAY, BOX_SHADOW_2, WHITE } from '../../../theme';

const StyledGlobalAlertContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  gap: 4px;

  position: fixed;
  bottom: 24px;
  left: 24px;
`;

export const StyledGlobalAlertItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  border-radius: 8px;
  padding: 8px 16px;

  box-shadow: ${BOX_SHADOW_2};
  border: 1px solid ${BORDER_GRAY};
  background: ${WHITE};
`;

export const GlobalAlerts: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <StyledGlobalAlertContainer id="global-alert-container">
    {children}
  </StyledGlobalAlertContainer>
);
